<template>
    <div class="m-other-products">
        <div
            v-for="item in otherProducts"
            :key="item.id"
            class="m-other-products-item"
            @click="onClick(item.pkgname)"
        >
            <img
                :src="
                    require(`@/assets/images/mobile/container/downloads/pic_${item.id}.png`)
                "
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            otherProducts: [
                {
                    pkgname: "com.kimoo.pinyin.nearme.gamecenter",
                    id: 1
                },
                {
                    pkgname: "com.kimoo.shizi",
                    id: 2
                },
                {
                    pkgname: "com.kimoo.english",
                    id: 4
                },
                {
                    pkgname: "com.kimoo.phonics",
                    id: 3
                }
            ]
        };
    },

    methods: {
        onClick(pkgname) {
            window.open(`http://a.app.qq.com/o/simple.jsp?pkgname=${pkgname}`);
        }
    }
};
</script>

<style lang="less" scoped>
.m-other-products {
    display: flex;
    flex-wrap: wrap;
    padding: 0 calc(100vw / 1080 * 45);
    margin-bottom: calc(100vw / 1080 * 120);

    &-item {
        margin-bottom: calc(100vw / 1080 * 30);

        img {
            width: calc(100vw / 1080 * 480);
        }

        &:nth-child(2n) {
            margin-left: calc(100vw / 1080 * 30);
        }
    }
}
</style>
