import { render, staticRenderFns } from "./mobile-other-products.vue?vue&type=template&id=1c127361&scoped=true&"
import script from "./mobile-other-products.vue?vue&type=script&lang=js&"
export * from "./mobile-other-products.vue?vue&type=script&lang=js&"
import style0 from "./mobile-other-products.vue?vue&type=style&index=0&id=1c127361&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c127361",
  null
  
)

export default component.exports