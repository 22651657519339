<template>
    <div>
        <picture v-if="!isMobile">
            <source
                media="(max-width: 1024px)"
                srcset="@/assets/images/mobile/container/downloads/banner.png"
            />
            <source
                media="(min-width: 1025px)"
                srcset="@/assets/images/container/downloads/banner.png"
            />
            <img
                class="banner"
                src="@/assets/images/container/downloads/banner.png"
            />
        </picture>

        <!-- app下载 -->

        <MobileDownloadWays v-if="isMobile" />
        <DownloadWays v-else />

        <!-- 其他产品 -->
        <div class="other-products">
            <img
                class="other-products__title"
                src="@/assets/images/container/downloads/title.png"
            />

            <MobileOtherProducts v-if="isMobile" />

            <template v-else>
                <div class="other-products-list">
                    <div
                        v-for="(item, idx) in otherProducts"
                        :key="idx"
                        class="other-products-item"
                        @mouseover="onMouseover(idx)"
                        @mouseout="onMouseout(idx)"
                    >
                        <img
                            class="other-products__title"
                            :src="
                                require(`@/assets/images/container/downloads/pic_${idx +
                                    1}.png`)
                            "
                            v-show="!item.isHover"
                        />

                        <img
                            class="other-products__qrcode"
                            :src="
                                require(`@/assets/images/container/downloads/qrcode_${idx +
                                    1}.png`)
                            "
                            v-show="item.isHover"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import DownloadWays from "./download-ways.vue";
import MobileDownloadWays from "./mobile-download-ways";
import MobileOtherProducts from "./mobile-other-products";

export default {
    components: {
        DownloadWays,
        MobileDownloadWays,
        MobileOtherProducts
    },

    data() {
        return {
            otherProducts: [
                { isHover: 0 },
                { isHover: 0 },
                { isHover: 0 },
                { isHover: 0 }
            ],

            isMobile: this.$userAgent.isMobile
        };
    },

    methods: {
        onMouseover(idx) {
            this.otherProducts[idx].isHover = true;
        },

        onMouseout(idx) {
            this.otherProducts[idx].isHover = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
    .banner {
        width: 100%;
        margin-bottom: calc(100vw / 1920 * 50) !important;
    }

    .downloads {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(100vw / 1920 * 150);

        &-item {
            > div {
                width: calc(100vw / 1920 * 240);
                height: calc(100vw / 1920 * 288);
                background-image: url("../assets/images/container/products/download_bg.png");
                background-size: 100% 100%;
                background-position: 0 0;
                color: #383838;
                text-align: center;
                position: relative;
            }

            + .downloads-item {
                margin-left: calc(100vw / 1920 * 45);
            }
        }

        &-qrcode {
            font-size: calc(100vw / 1920 * 24);
            padding-top: calc(100vw / 1920 * 20);
            line-height: 1;

            &__img {
                width: calc(100vw / 1920 * 190);
                height: calc(100vw / 1920 * 190);
                margin-bottom: calc(100vw / 1920 * 16);
            }
        }

        &-apple {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_2.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_1.png");
                }
            }
        }

        &-android {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_3.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_4.png");
                }
            }
        }

        &-app {
            font-size: calc(100vw / 1920 * 20);

            img {
                width: calc(100vw / 1920 * 241);
                height: calc(100vw / 1920 * 288);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-btn {
            width: calc(100vw / 1920 * 208);
            height: calc(100vw / 1920 * 64);
            background-size: 100% 100%;
            background-position: 0 0;
            margin: calc(100vw / 1920 * 15) auto 0;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }

        &-msg {
            display: flex;
            justify-content: center;
            padding-top: calc(100vw / 1920 * 149);
            line-height: 1.25;
            z-index: 1;
            position: relative;

            &__label {
                text-align: right;
            }

            &__value {
                text-align: left;
            }
        }
    }

    .other-products {
        text-align: center;

        &__title {
            width: calc(100vw / 1920 * 336);
            margin-bottom: calc(100vw / 1920 * 35);
        }

        &-list {
            display: flex;
            justify-content: center;
            margin-bottom: calc(100vw / 1920 * 284);
        }

        &-item {
            width: calc(100vw / 1920 * 176);
            height: calc(100vw / 1920 * 250);
            background-image: url("../assets/images/container/downloads/pic.png");
            background-size: 100% 100%;
            background-position: 0 0;
            position: relative;

            img {
                width: 100%;
            }

            + .other-products-item {
                margin-left: calc(100vw / 1920 * 60);
            }
        }

        &__qrcode {
            width: calc(100vw / 1920 * 144) !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
};

@media (max-width: 1024px) {
    .banner {
        margin-bottom: calc(100vw / 1080 * 50) !important;
    }

    .container {
        padding: 0 !important;
    }

    .downloads {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: calc(100vw / 1920 * 132);

        &-item {
            > div {
                width: calc(100vw / 1080 * 467);
                height: calc(100vw / 1080 * 558);
                background-image: url("../assets/images/container/products/download_bg.png");
                background-size: 100% 100%;
                background-position: 0 0;
                color: #383838;
                text-align: center;
                position: relative;
            }

            + .downloads-item {
                margin-left: calc(100vw / 1080 * 48);
            }

            &:nth-child(1) {
                width: 100%;
                margin-bottom: calc(100vw / 1080 * 42);
            }
        }

        &-qrcode {
            font-size: calc(100vw / 1080 * 42);
            padding-top: calc(100vw / 1080 * 38);
            margin: 0 auto;

            &__img {
                width: calc(100vw / 1080 * 351);
                height: calc(100vw / 1080 * 351);
                margin-bottom: calc(100vw / 1080 * 28);
            }
        }

        &-apple {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_2.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_1.png");
                }
            }
        }

        &-android {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_3.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_4.png");
                }
            }
        }

        &-app {
            font-size: calc(100vw / 1080 * 36);

            img {
                width: calc(100vw / 1080 * 450);
                height: calc(100vw / 1080 * 540);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-btn {
            width: calc(100vw / 1080 * 390);
            height: calc(100vw / 1080 * 120);
            background-size: 100% 100%;
            background-position: 0 0;
            margin: calc(100vw / 1080 * 29) auto 0;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }

        &-msg {
            display: flex;
            justify-content: center;
            padding-top: calc(100vw / 1080 * 271);
            line-height: 1.25;

            &__label {
                text-align: right;
            }

            &__value {
                text-align: left;
            }
        }
    }

    .other-products {
        text-align: center;
        margin-top: calc(100vw / 1080 * 160);

        &__title {
            width: calc(100vw / 1080 * 630);
            margin-bottom: calc(100vw / 1080 * 80);
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: calc(100vw / 1080 * 192);
        }

        &-item {
            width: calc(100vw / 1080 * 397);
            height: calc(100vw / 1080 * 551);
            background-image: url("../assets/images/container/downloads/pic.png");
            background-size: 100% 100%;
            background-position: 0 0;
            position: relative;
            margin-bottom: calc(100vw / 1080 * 60);

            img {
                width: 100%;
            }

            + .other-products-item {
                margin-left: calc(100vw / 1080 * 70);
            }

            &:nth-child(2n + 1) {
                margin-left: 0;
            }
        }

        &__qrcode {
            width: calc(100vw / 1080 * 351) !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
