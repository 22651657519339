<template>
	<div class="g-page">
		<Nav />

		<Downloads />

		<Footer />

		<BackToTop />
	</div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Downloads from "@/components/downloads.vue";
import Footer from "@/components/footer.vue";
import BackToTop from "@/components/back-to-top.vue";

export default {
	components: {
		Nav,
		Downloads,
		Footer,
		BackToTop
	},

	mounted() {}
};
</script>

<style lang="less">
.banner {
	width: 100%;
	margin-bottom: 80px;
}
</style>
